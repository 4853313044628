/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
import "regenerator-runtime/runtime"
import "./src/styles/global.css"
import posthog from "./src/utils/posthog"

export const onRouteUpdate = ({ location }) => {
  posthog.capture("$pageview")
}
